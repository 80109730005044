import React, {useEffect, useContext} from 'react';
import Layout from "../../components/layout";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FabContext } from "../../context/FabContext";
import './style.scss';

const Home = () => {

    const{firstRow,secondRow,thirdRow,testimonials,setLocal,loading} = useContext(FabContext);

    useEffect(()=>{ setLocal('home');},[]);


    const firstGallery = firstRow.map((pic,index) =>  <li style={{backgroundImage: `url(../../images/home-brides/${pic})`}} key={index}></li>)
    const secondGallery = secondRow.map((pic,index) =>  <li style={{backgroundImage: `url(../../images/home-brides/${pic})`}} key={index}></li>)
    const thirdGallery = thirdRow.map((pic,index) =>  <li style={{backgroundImage: `url(../../images/home-brides/${pic})`}} key={index}></li>)
    const testSection = testimonials.slice(0, 3).map((test,index)=>
        <AniLink cover direction="right" bg="#ffffff" duration={1} to={`/testimonials/${test.id}`}>
            <li key={index} style={{backgroundImage: `url(../../images/testimonials/${test.url})`}}>
                <h3>{test.name}</h3>
            </li>
        </AniLink>
    )

    return(
        <Layout>
        <div className={`home ${loading ? 'hide' : 'show'}`}>

            <div className="intro">
                <div className="intro__text">
                    <h1 className="intro__text__headline">Bridal styling to make you feel <span>FAB</span>ulous</h1>
                    <p className="intro__text__body"> Having a partner to guide you through the wedding dress shopping process can eliminate the stress, and elevate the excitement, and I am here to help you find your Bridal Style.   Whether it’s finding your gown, or getting you ready on your wedding day, I am here to take care of it all! </p>
                    <h4 className="intro__text__attribution">&copy; Jamie Levine Photography</h4>
                </div>
                <div className="intro__gradient"></div>
            </div>

            <div className="divider" />

            <div className="experiences">
                    <AniLink cover direction="right" bg="#ffffff" duration={1} to="/experiences">
                        <div className="experiences__center">
                            <div className="experiences__center__text-container">
                                    <div className="experiences__center__text-container__text">
                                        <h5 className="experiences__center__text-container__text__flag">EXPERIENCES</h5>
                                        <h4 className="experiences__center__text-container__text__headline">There is nothing more important to me than having my brides look and feel absolutely fabulous on their wedding day.</h4>
                                        <p className="experiences__center__text-container__text__body">At FAB Styling, I am here to offer unique one-on-one experiences to my brides to ensure a picture-perfect wedding day.  I have learned the ins and outs and all of the bridal secrets from my many years working at numerous iconic bridal salons, and want to share them with my brides so they can have the best experience, custom-tailored to their needs.</p>
                                        <button className="experiences__center__text-container__text__cta">Learn More</button>
                                    </div>
                            </div>

                            <div className="experiences__center__image-container">
                                    <h4 className="experiences__center__image-container__attribution">&copy; Jamie Levine Photography</h4>
                            </div>

                        </div>
                    </AniLink>
            </div>

            <div id="brides" className="brides">
                 <AniLink cover direction="right" bg="#ffffff" duration={1} to="/brides">
                            <div className="brides__galleries">

                                    <ul 
                                    className="brides__galleries__first"
                                    data-scroll
                                    data-scroll-speed="1"
                                    data-scroll-direction="horizontal"
                                    data-scroll-target="#brides"
                                    >{firstGallery}
                                    </ul>

                                    <ul 
                                    className="brides__galleries__second"
                                    data-scroll
                                    data-scroll-speed="2"
                                    data-scroll-direction="horizontal"
                                    data-scroll-target="#brides"
                                    >{secondGallery}
                                    </ul>

                                    <ul 
                                    className="brides__galleries__third"
                                    data-scroll
                                    data-scroll-speed="1"
                                    data-scroll-direction="horizontal"
                                    data-scroll-target="#brides"
                                    >{thirdGallery}
                                    </ul>

                            </div>
                            <div className="brides__goTo">
                                        <div className="brides__goTo__left">
                                                <h5 className="brides__goTo__left__flag">PORTFOLIO</h5>
                                                <h4 className="brides__goTo__left__headline">My brides<br/>are beautiful!</h4>
                                        </div>
                                        
                                        <div className="brides__goTo__right">
                                                <p className="brides__goTo__right__body">I love to showcase my brides and how happy they are on their wedding day!  Nothing makes me more excited than seeing a bride radiate confidence in her dream dress. </p>
                                                <button className="brides__goTo__right__cta">See More</button>
                                        </div>
                            </div>
                    </AniLink>
            </div>

            <AniLink cover direction="right" bg="#ffffff" duration={1} to="/about">
                <div className="farrah">
                            <div className="farrah__center">
                                <div className="farrah__center__portrait"><h4 className="farrah__center__portrait__attribution">&copy; Jamie Levine Photography</h4></div>

                                <div className="farrah__center__text">
                                    <h5 className="farrah__center__text__flag">GIRL BEHIND THE GOWNS</h5>
                                    <h4 className="farrah__center__text__headline">Farrah Arenson</h4>
                                    <p className="farrah__center__text__copy"> Your wedding day is arguably the most important day of your life- the day you begin a new chapter with your partner and take on the world together, and I want to help you shine on that day. As a bridal consultant, I realized I could do exactly that.</p>
                                    <button className="farrah__center__text__cta">More on Farrah</button>
                                </div>
                            </div>
                            <div className="farrah__blue" />
                </div>
            </AniLink>

            <div className="testimonials">
                     <div className="testimonials__center">
                        <h5 className="testimonials__center__flag">TESTIMONIALS</h5>
                        <ul className="testimonials__center__grid"> {testSection} </ul>
                        <AniLink cover direction="right" bg="#ffffff" duration={1} to="/testimonials">
                            <div className="testimonials__center__text">
                                    <div className="testimonials__center__text__left">
                                        <h4 className="testimonials__center__text__left__headline">Don't take it from me,<br/>take it from them</h4>
                                    </div>
                                    <div className="testimonials__center__text__right">
                                        <p className="testimonials__center__text__right__copy">I am grateful to all of my beautiful brides and the experiences we have shared together!</p>
                                        <button className="testimonials__center__text__right__cta">See Everyone</button>
                                    </div>

                            </div>
                        </AniLink>
                </div>
            </div>
        </div>
        </Layout>
    )
}
export default Home;
